import React from "react";

const FAQs = () => {
  return (
    <section
      id="faq"
      className="section"
      style={{
        position: "relative",
        backgroundImage: `url('images/bg.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
        padding: "50px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "50px",
        minHeight: "100vh",
        overflowX: "hidden", // Impede rolagem horizontal
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />
      <div className="container" style={{ position: "relative", zIndex: 2 }}>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}
        >
          <div
            className="col-lg-6 col-md-6 col-12"
            style={{
              padding: "10px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              paddingLeft: "50px", // Espaçamento adicional para notebook
            }}
          >
            <h2
              style={{
                color: "#FFF",
                fontFamily: "Poppins",
                fontSize: "52px", // Tamanho reduzido para dispositivos móveis
                fontStyle: "normal",
                fontWeight: 900,
                lineHeight: "60px", // Altura da linha ajustada
                marginBottom: "30px",
              }}
              className="title"
            >
              <span>Uma parceria</span> <br />
              <span>de sucesso!</span>
            </h2>
            <h4
              style={{
                fontFamily: "Poppins",
                fontSize: "25px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "40px", // Espaçamento adicionado à altura da linha
                textTransform: "uppercase",
                marginBottom: "30px",
              }}
            >
              Conexão + Align™
            </h4>
            <p style={{ marginBottom: "20px" }}>
              Milhares de soluções protéticas para consultar a hora que quiser.
              Garanta alta performance com nossa mega biblioteca. Agora
              disponível no ambiente exocad™️, oferecendo soluções completas para
              próteses.
            </p>
            <h5
              style={{
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: 600,
                marginBottom: "10px",
              }}
            >
              Mapa da Mega Biblioteca Digital Conexão Align™
            </h5>
            <a
              href="images/pdf1.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "inline-block",
                padding: "10px 20px",
                borderRadius: "6px",
                background: "linear-gradient(90deg, #4CA4FF 0%, #7AA3CE 100%)",
                color: "white",
                textDecoration: "none",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              Ter Acesso à Biblioteca
            </a>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <img
              src="images/biblioteca.png"
              alt="Biblioteca"
              style={{
                width: "150%",
                height: "auto",
                borderRadius: "5px",
                maxWidth: "100%", // Garante que a imagem não exceda o contêiner
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
