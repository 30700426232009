import React from "react";
import { Parallax } from "react-parallax";

function Counter() {
  return (
    <section id="counter" className="hero-wrap">
      <div className="hero-mask opacity-8 bg-dark" />

      <Parallax
        bgImage="images/intro-bg-4.jpg"
        bgImageAlt="Intro"
        strength={200}
      >
        <div className="hero-content section">
          <div className="container text-center">
            <h2 className="text-white mb-4">BAIXAR NOSSOS PDF</h2>
            <div className="row gy-5 justify-content-center">
              <div className="col-md-4">
                <a href="images/pdf1.pdf" className="btn btn-primary mb-2 w-100" target="_blank" rel="noopener noreferrer">
                  Biblioteca Digital
                </a>
              </div>
              <div className="col-md-4">
                <a href="images/pdf2.pdf" className="btn mb-2 w-100" target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "#b8860b", color: "#000" }}>
                  Flex Gold
                </a>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </section>
  );
}

export default Counter;
