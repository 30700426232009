import React, { useState } from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";

function SideHeader({ darkTheme = false }) {
  const [isNavModalClose, setIsNavModalClose] = useState(true);

  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav
        className={
          "primary-menu navbar navbar-expand-lg fw-500 text-4 " +
          (darkTheme ? "navbar-dark bg-dark border-bottom-0" : "")
        }
      >
        <div className="container-lg position-relative h-100 flex-lg-column px-lg-3 px-lg-5 py-lg-4">
          {/* Logo */}
          <a
            className="logo text-lg-end d-lg-block mt-lg-3 mb-lg-4"
            href="/"
            title="MXapp"
          >
            <img
              src={darkTheme ? "images/logo-light.png" : "images/logo-2.png"}
              alt="MXapp"
            />
          </a>
          {/* Logo End */}
          <div
            id="header-nav"
            className={
              "collapse navbar-collapse w-100 " +
              (isNavModalClose ? "" : "show")
            }
          >
            <ul className="navbar-nav my-lg-auto text-lg-end">
              {/* FAQ renamed to Inicio */}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="faq" // ID correspondente à seção F.A.Q.
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Inicio
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="Testimonials" // Certifique-se de que o ID é "Testimonials"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Depoimentos
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="counter" // Adapte isso para o ID correto do PDF
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Baixar Pdf
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="media" // ID corrigido para a galeria
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Galeria
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="about"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  IN24
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="contact"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Contato
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="features"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Destaque
                </Link>
              </li>
            </ul>
          </div>
          <button
            className={
              "navbar-toggler " + (isNavModalClose ? "collapsed" : "show")
            }
            type="button"
            onClick={() => setIsNavModalClose(!isNavModalClose)}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </nav>
    </header>
  );
}

export default SideHeader;
