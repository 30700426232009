import React from "react";
import {
  HeaderConfig,
  IntroContentConfig,
  IntroThemeConfig,
  appliedConfig,
} from "../config/commonConfig";

const AboutUs = () => {
  return (
    <section
      id="about"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div
        className={
          "container " +
          (appliedConfig.appliedIntro === IntroThemeConfig.Intro1 &&
          appliedConfig.appliedIntroContent === IntroContentConfig.Image
            ? "pt-5 "
            : " ")
        }
      >
        <h2 className="text-9 fw-600 text-center">MUITO OBRIGADO!</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />

        <div className="row">
          <div className="col-lg-6 text-center">
            {/* Substituí o vídeo pela imagem in24.png */}
            <img
              src="images/in24.png"
              alt="IN24"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-6 text-center text-lg-start">
            <h2 className="text-6 mb-3 mt-4">
              A Conexão agradece por toda sua parceria no IN24 e na vida!
            </h2>
            <p>
              Seu apoio e participação são essenciais para que possamos
              continuar buscando o melhor da Odontologia Digital.
            </p>

            <div className="d-inline-flex pt-2">
              <a
                href="https://www.youtube.com/watch?v=VybM5sS3KHo&t=5s&ab_channel=Conex%C3%A3oSistemasdePr%C3%B3tese%7COdontologiaDigital"
                target="_blank" // Abre em nova aba
                rel="noopener noreferrer" // Melhora a segurança ao abrir links externos
                className="btn btn-primary"
                style={{
                  borderRadius: "6px",
                  background: "linear-gradient(90deg, #4CA4FF 0%, #7AA3CE 100%)",
                  border: "none",
                  padding: "10px 20px",
                  color: "#fff",
                  fontWeight: "bold",
                  textDecoration: "none", // Remove o sublinhado do link
                }}
              >
                Assistir vídeo completo
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
