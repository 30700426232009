import React from "react";

function TermsAndConditions({ isOpen, onClose }) {
  return (
    <div
      id="terms"
      className={`modal fade ${isOpen ? "show" : ""}`}
      role="dialog"
      aria-hidden={!isOpen}
      style={{ display: isOpen ? "block" : "none" }} // Controla a exibição
    >
      <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Terms of Use</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4" style={{ backgroundColor: "#003D66" }}>
            <div className="container">
              <div className="row">
                {/* Coluna Esquerda */}
                <div className="col-md-6 text-left">
                  <h2 style={{
                    color: "#FFF",
                    fontFamily: "Montserrat",
                    fontSize: "60px",
                    fontStyle: "normal",
                    fontWeight: 900,
                    lineHeight: "64px"
                  }}>
                    Uma parceria de sucesso!
                  </h2>
                  <h3 style={{
                    fontFamily: "Montserrat",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "0px",
                    letterSpacing: "10px",
                    textTransform: "uppercase",
                    color: "#FFF"
                  }}>
                    Conexão
                  </h3>
                  <p style={{ color: "#FFF" }}>
                    Milhares de soluções protéticas para consultar a hora que quiser.<br /><br />
                    Garanta alta performance com nossa mega biblioteca. Agora disponível no ambiente exocad™️, oferecendo soluções completas para próteses.
                  </p>
                  {/* Div que se comporta como um botão */}
                  <div style={{
                    borderRadius: "6px",
                    background: "linear-gradient(90deg, #4CA4FF 0%, #7AA3CE 100%)",
                    color: "#FFF",
                    padding: "10px 20px",
                    cursor: "pointer",
                    fontSize: "16px",
                    display: "inline-block", // Para que o div tenha o comportamento de um botão
                    textAlign: "center"
                  }} onClick={() => alert('Acesso à biblioteca')} >
                    TER ACESSO A BIBLIOTECA
                  </div>
                </div>

                {/* Coluna Direita (vazia por enquanto) */}
                <div className="col-md-6">
                  {/* Você pode adicionar conteúdo aqui, se necessário */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
