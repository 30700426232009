import React from "react";
import Slider from "react-slick";
import { HeaderConfig, appliedConfig } from "../config/commonConfig";

const AppScreenshots = () => {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: false, // Desativar a repetição infinita
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <section
      id="media"
      className={
        "section bg-light " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div className="container">
        <h2 className="text-9 fw-600 text-center">Galeria de Imagens e Vídeos</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-4"></p>
        <Slider {...settings}>
          {/* Imagens existentes */}
          <div className="gallery-item shadow-sm">
            <img className="img-fluid" alt="Imagem 1" src="images/app/01.jpg" />
          </div>
          <div className="gallery-item shadow-sm">
            <img className="img-fluid" alt="Imagem 2" src="images/app/02.jpg" />
          </div>
          <div className="gallery-item shadow-sm">
            <img className="img-fluid" alt="Imagem 3" src="images/app/03.jpg" />
          </div>

          {/* Vídeos antigos */}
          <div className="gallery-item shadow-sm">
            <video className="img-fluid" controls>
              <source src="images/app/05.mp4" type="video/mp4" />
              Seu navegador não suporta o vídeo.
            </video>
          </div>
          <div className="gallery-item shadow-sm">
            <video className="img-fluid" controls>
              <source src="images/app/06.mp4" type="video/mp4" />
              Seu navegador não suporta o vídeo.
            </video>
          </div>

          {/* Novos vídeos */}
          <div className="gallery-item shadow-sm">
            <video className="img-fluid" controls>
              <source src="images/app/video1.mp4" type="video/mp4" />
              Seu navegador não suporta o vídeo.
            </video>
          </div>
          <div className="gallery-item shadow-sm">
            <video className="img-fluid" controls>
              <source src="images/app/video2.mp4" type="video/mp4" />
              Seu navegador não suporta o vídeo.
            </video>
          </div>
          <div className="gallery-item shadow-sm">
            <video className="img-fluid" controls>
              <source src="images/app/video3.mp4" type="video/mp4" />
              Seu navegador não suporta o vídeo.
            </video>
          </div>
          <div className="gallery-item shadow-sm">
            <video className="img-fluid" controls>
              <source src="images/app/video4.mp4" type="video/mp4" />
              Seu navegador não suporta o vídeo.
            </video>
          </div>

          {/* Novas imagens com tamanho maior */}
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto1" src="images/app/foto1.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto2" src="images/app/foto2.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto3" src="images/app/foto3.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto4" src="images/app/foto4.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto5" src="images/app/foto5.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto6" src="images/app/foto6.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto7" src="images/app/foto7.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto8" src="images/app/foto8.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto9" src="images/app/foto9.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto10" src="images/app/foto10.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto11" src="images/app/foto11.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto12" src="images/app/foto12.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto13" src="images/app/foto13.jpg" />
          </div>
          <div className="gallery-item shadow-sm large-image">
            <img className="img-fluid" alt="Imagem foto14" src="images/app/foto14.jpg" />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default AppScreenshots;
