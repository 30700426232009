import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from "react-player/vimeo"; // Importa o player do Vimeo
import { HeaderConfig, appliedConfig } from "../config/commonConfig";

const Testimonials = () => {
  const SlickArrowLeft = ({ ...props }) => (
    <button
      {...props}
      className={"slick-prev slick-arrow"}
      aria-hidden="true"
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );

  const SlickArrowRight = ({ ...props }) => (
    <button
      {...props}
      className={"slick-next slick-arrow"}
      aria-hidden="true"
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  const settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    autoplay: false, // Desativado para não passar automaticamente
    slidesToShow: 4, // Exibe 4 vídeos por vez na tela grande
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Para dispositivos com largura menor que 768px
        settings: {
          slidesToShow: 1, // Exibe apenas 1 vídeo por vez em dispositivos móveis
        },
      },
    ],
  };

  // URLs dos vídeos do Vimeo
  const videoUrls = [
    "https://vimeo.com/1018076489",
    "https://vimeo.com/1018076500",
    "https://vimeo.com/1018076513",
    "https://vimeo.com/1018076535",
    "https://vimeo.com/1018076545",
    "https://vimeo.com/1018076566",
    "https://vimeo.com/1018076593",
    "https://vimeo.com/1018076617",
    "https://vimeo.com/1018076642",
    "https://vimeo.com/1018076666",
  ];

  return (
    <section
      id="Testimonials"
      className={
        "section bg-light " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div className="container">
        <h2 className="text-9 fw-600 text-center">Depoimentos</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-4"></p>
        <Slider {...settings}>
          {/* Exibindo apenas os vídeos */}
          {videoUrls.map((url, index) => (
            <div className="gallery-item shadow-sm" key={index} style={{ backgroundColor: "transparent" }}>
              <div style={{ position: "relative", width: "100%", height: "500px" }}>
                <ReactPlayer
                  url={url} // URL do vídeo do Vimeo
                  controls={true} // Mostra os controles do player
                  width="100%" // Largura do player
                  height="100%" // Ajusta a altura do player para 100% do contêiner
                  style={{ position: "absolute", top: 0, left: 0 }} // Faz com que o player ocupe toda a área do contêiner
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
